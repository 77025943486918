import React from 'react';
import { Link } from 'gatsby';

import { LightThemeLeftWebinarLogo } from 'kalanso-plugin-logos';

import Headline from 'gatsby-theme-kalanso-ui/src/components/text/Headline';

import _styles from 'src/components/pages/success/success.module.scss';
import _buttonStyles from 'src/components/buttons/buttons.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss'

const NotFound = () => {
  return (
    <section className={_styles.container}>
      <div className={`${_styles.inner}`}>
        <header className={`${_styles.head}`}>
          <Link to="/">
            <LightThemeLeftWebinarLogo />
          </Link>
        </header>
        <div className={`${_styles.body}`}>
          <Headline className={`${_styles.headline}`}>
            Sorry - that page doesn't exist.
          </Headline>

          <div
            className={`
              ${_utils.mt32x} 
              ${_buttonStyles.center} 
              ${_buttonStyles.fullWidth} 
              ${_buttonStyles.autoWidthTabletAndUp}
              ${_buttonStyles.large}
            `}
          >
            <Link
              to="/"
              className={`${_buttonStyles.button} ${_buttonStyles.primary} ${_buttonStyles.asButton}`}
            >
              Register for the next webinar
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

NotFound.propTypes = {};

NotFound.defaultProps = {};

export default NotFound;
