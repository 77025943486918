import React from 'react';

import formatClassNames from '../../utils/formatClassNames'

import _defaultStyles from './text.module.scss';

const Headline = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.headline, className])

  return (
    <h1 className={classes} {...rest}>
      {children}
    </h1>
  );
};

export default Headline;
