import React from 'react';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';

import NotFound from "src/components/NotFound"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404 - Not Found" />
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
