import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'gatsby-theme-kalanso-ui/src/components/footer/Footer';

const Layout = ({ children, showJunkDrawer }) => {
  return (
    <>
      <main>{children}</main>
      {showJunkDrawer && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showJunkDrawer: PropTypes.bool
};

Layout.defaultProps = {
  showJunkDrawer: true
}

export default Layout;
